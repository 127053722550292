<template>
  <v-main>
    <v-container fluid page>
      <v-row align="center">
        <v-col cols="12">
          <h2 class="display-1 font-weight-light pt-5 pl-5">
            {{ $t('dailyMenus.title') }}
          </h2>
        </v-col>
      </v-row>

      <v-row v-show="!loading" align="center">
        <v-col cols="12" v-if="error">
          <v-alert border="left" colored-border type="error" elevation="2">
            <strong v-if="code">{{ code }}</strong>
            {{ message }}
          </v-alert>
        </v-col>

        <v-col cols="12" v-if="dailyMenus.length == 0">
          <v-alert border="left" colored-border type="info" elevation="2">{{
            $t('dailyMenus.noDailyMenus')
          }}</v-alert>
        </v-col>
      </v-row>

      <draggable
        v-show="!loading"
        v-model="dailyMenus"
        handle=".handle"
        tag="div"
        class="row align-stretch"
        v-bind="dragOptions"
      >
        <!-- Daily menus cards -->
        <v-col
          cols="12"
          sm="6"
          md="4"
          xl="3"
          class="d-flex"
          v-for="dailyMenu in dailyMenus"
          :key="dailyMenu.id"
        >
          <EasyServeDailyMenu
            :daily-menu="dailyMenu"
            @daily-menu-products="products(dailyMenu)"
            @daily-menu-delete="remove(dailyMenu)"
            @daily-menu-edit="edit(dailyMenu)"
          ></EasyServeDailyMenu>
        </v-col>
        <!-- Daily menus cards -->
      </draggable>

      <!-- Create fab button -->
      <v-fab-transition>
        <v-btn
          color="red"
          fab
          dark
          large
          bottom
          right
          ripple
          fixed
          @click="create"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-fab-transition>
    </v-container>

    <!-- Remove buttom sheet -->
    <v-bottom-sheet v-model="removeDialog">
      <v-sheet class="text-center px-3" min-height="200px" v-if="dailyMenu">
        <div class="pt-6">
          ¿Estás segur@ que quieres eliminar
          <span class="font-weight-medium"
            >'{{ dailyMenu.name | translate($i18n) }}'</span
          >?
        </div>

        <div
          class="py-3"
          v-if="dailyMenu != null && dailyMenu.products_count > 0"
        >
          <div>Por favor, seleccione de abajo un menú para los productos.</div>

          <v-row>
            <v-col
              cols="12"
              sm="8"
              offset-sm="2"
              md="6"
              offset-md="3"
              lg="4"
              offset-lg="4"
            >
              <v-select
                class="mt-2"
                v-model="substituteDailyMenu"
                :items="substituteDailyMenus"
                prepend-icon="mdi-shape"
                label="Seleccione un menú de la lista"
                item-value="id"
                :item-text="itemText"
                width="300px"
              ></v-select>
            </v-col>
          </v-row>
        </div>

        <div class="pb-5">
          <v-btn class="mt-6 mx-2" @click="removeCanceled">Cancelar</v-btn>
          <v-btn
            class="mt-6 mx-2"
            color="error darken-1"
            @click="removeConfirmed"
            :disabled="
              dailyMenu != null &&
              dailyMenu.products_count > 0 &&
              substituteDailyMenu == -1
            "
          >
            Elminar
          </v-btn>
        </div>
      </v-sheet>
    </v-bottom-sheet>
    <!-- Remove buttom sheet -->

    <!-- Loading screen -->
    <eximyr-loading :loading="loading"></eximyr-loading>
    <!-- Loading screen -->
  </v-main>
</template>

<script>
import errors from '../mixins/errors';
import draggable from 'vuedraggable';
import { mapGetters } from 'vuex';

import EximyrLoading from '../components/EximyrLoading';
import EasyServeDailyMenu from './components/EasyServeDailyMenu';

export default {
  mixins: [errors],

  components: {
    draggable,
    EximyrLoading,
    EasyServeDailyMenu,
  },

  data: () => ({
    dailyMenu: null,
    substituteDailyMenu: -1,

    removeDialog: false,
  }),

  computed: {
    ...mapGetters('dailyMenus', {
      loading: 'loading',
    }),

    dailyMenus: {
      get() {
        return this.$store.state.dailyMenus.dailyMenus;
      },

      set(dailyMenus) {
        this.reorder(dailyMenus);
        dailyMenus.forEach(v => console.log(v.name.es));
      },
    },

    substituteDailyMenus() {
      const substituteDailyMenus = this.dailyMenus.filter(
        dailyMenu => dailyMenu.id !== this.dailyMenu.id
      );

      substituteDailyMenus.unshift({
        // FIXME: Multi-Language
        name: {
          es: 'Elimina todos los productos.',
          en: 'Remove all products.',
          de: 'Entfernen Sie alle Produkte.',
          it: 'Rimuovi tutti i prodotti.',
          fr: 'Supprimer tous les produits.',
          pt: 'Remova todos os produtos.',
        },
        id: 0,
      });

      return substituteDailyMenus;
    },

    itemText() {
      // Needed in the select for daily menu substitution (remove daily menu feature)
      return 'name[' + this.$i18n.locale + ']';
    },

    dragOptions() {
      return {
        animation: 200,
        group: 'categories',
        disabled: false,
        ghostClass: 'ghost',
      };
    },
  },

  created() {
    this.load();
  },

  methods: {
    load() {
      // Create the payload
      const payload = {
        restaurant: this.$route.params.restaurant,
      };

      this.$store
        .dispatch('dailyMenus/load', payload)
        .then(() => {
          this.clearErrors();
        })
        .catch(error => {
          this.storeError(error);
        });
    },

    products(dailyMenu) {
      this.$router.push({
        name: 'daily-menus.products',
        params: {
          restaurant: this.$route.params.restaurant,
          dailyMenu: dailyMenu.id,
        },
      });
    },

    // Create a new daily menu
    create() {
      this.$router.push({
        name: 'daily-menus.create',
      });
    },

    // Edit Daily Menus
    edit(dailyMenu) {
      // Clear all daily menus and move to the edit page
      this.$store
        .dispatch('dailyMenus/clear')
        .then(() => {
          // Clear all errors
          this.clearErrors();

          this.$router.push({
            name: 'daily-menus.edit',
            params: { dailyMenu: dailyMenu.id },
          });
        })
        .catch(error => {
          this.storeError(error);
        });
    },

    // Reorder daily menus
    reorder(dailyMenus) {
      const payload = {
        restaurant: this.$route.params.restaurant,
        dailyMenus: dailyMenus,
      };

      this.$store
        .dispatch('dailyMenus/reorder', payload)
        .then(() => {
          // Clear all errors
          this.clearErrors();
        })
        .catch(error => {
          this.storeError(error);
        });
    },

    remove(dailyMenu) {
      this.dailyMenu = dailyMenu;
      this.removeDialog = true;
    },
    removeCanceled() {
      this.substituteDailyMenu = -1;
      this.dailyMenu = null;
      this.removeDialog = false;
    },
    removeConfirmed() {
      const payload = {
        restaurant: this.$route.params.restaurant,
        dailyMenu: this.dailyMenu.id,
        substituteDailyMenu: this.substituteDailyMenu,
      };

      // Remove this daily menu and reload
      this.$store
        .dispatch('dailyMenus/remove', payload)
        .then(() => {
          // Once deleted, reload all daily menus
          this.load();
        })
        .catch(error => {
          this.storeError(error);
        });

      this.removeCanceled();
    },
  },
};
</script>
