export const requiredIf = (component, required) => value => {
  // If is not required then we pass the validation
  if (!required) return true;

  if (value != undefined && value != null) {
    // First we need to convert any value to string
    value = value.toString().trim();
  }

  return !!value || component.$t('validations.required');
};
