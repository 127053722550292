<template>
  <v-list-item @click.stop>
    <v-list-item-avatar v-if="$vuetify.breakpoint.smAndUp" class="elevation-3">
      <v-icon class="white--text" :class="classObject">{{ icon }}</v-icon>
    </v-list-item-avatar>

    <v-list-item-content>
      <v-list-item-title>
        {{ product.name | translate($i18n) }}
      </v-list-item-title>
      <v-list-item-subtitle>{{
        product.description | translate($i18n)
      }}</v-list-item-subtitle>
      <v-list-item-subtitle class="pt-1" v-if="product.allergens.length > 0">
        <v-avatar
          size="32"
          color="white"
          class="mr-1 mb-2 elevation-1"
          v-for="allergen in product.allergens"
          :key="product.id + '_allergen_' + allergen.id"
        >
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <img
                :src="allergen.image_path"
                v-on="on"
                :alt="allergen.name[$i18n.locale]"
              />
            </template>
            <span>{{ allergen.name[$i18n.locale] }}</span>
          </v-tooltip>
        </v-avatar>
      </v-list-item-subtitle>
    </v-list-item-content>

    <v-list-item-action v-if="product.price" class="ml-2 subtitle-2">{{
      product.price | currency_money
    }}</v-list-item-action>
    <v-list-item-action>
      <v-menu bottom left offset-y close-on-content-click>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list dense min-width="150">
          <v-list-item @click.stop="$emit('product-edit')">
            <v-list-item-title>Modificar</v-list-item-title>
          </v-list-item>
          <v-list-item @click.stop="$emit('product-delete')">
            <v-list-item-title>Eliminar</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import {
  BEVERAGES,
  FIRST_DISHES,
  SECOND_DISHES,
  DESSERTS,
  COFFEES,
} from '@/store/modules/dailyMenuProducts';

export default {
  props: {
    product: Object,
    last: Boolean,
  },
  computed: {
    icon() {
      if (this.product.group === BEVERAGES) return 'mdi-glass-wine';
      if (this.product.group === FIRST_DISHES) return 'mdi-silverware';
      if (this.product.group === SECOND_DISHES) return 'mdi-silverware';
      if (this.product.group === DESSERTS) return 'mdi-cupcake';
      if (this.product.group === COFFEES) return 'mdi-coffee';

      // Defaults to coffee
      return 'mdi-coffee';
    },

    classObject() {
      if (!this.product.active) return 'blue-grey lighten-3';

      if (this.product.group === BEVERAGES) return 'purple accent-4';
      if (this.product.group === FIRST_DISHES) return 'deep-orange accent-4';
      if (this.product.group === SECOND_DISHES) return 'deep-orange accent-4';
      if (this.product.group === DESSERTS) return 'amber accent-4';
      if (this.product.group === COFFEES) return 'blue accent-4';

      // Defaults to blue
      return 'blue accent-4';
    },
  },
};
</script>

<style></style>
