<template>
  <v-card
    class="mx-auto header category d-flex flex flex-column"
    @click="$emit('daily-menu-edit')"
  >
    <v-img
      class="white--text flex-grow-0"
      height="200px"
      :src="dailyMenu.image_path"
    >
      <v-card-title class="align-content-space-between fill-height pa-0">
        <div class="pa-3 text-truncate text-right options">
          <v-chip class="ma-2 elevation-3 handle" color="white" @click.stop>
            <v-avatar left>
              <v-icon>mdi-sort</v-icon>
            </v-avatar>
            {{ dailyMenu.display_priority }}
          </v-chip>

          <v-chip class="ma-2 elevation-3" color="white">
            <v-avatar>
              <v-icon>{{
                dailyMenu.active ? 'mdi-eye' : 'mdi-eye-off'
              }}</v-icon>
            </v-avatar>
          </v-chip>

          <v-chip class="ma-2 elevation-3" color="white">
            <v-avatar>
              <v-icon>mdi-currency-eur</v-icon>
            </v-avatar>
            <strong>{{ dailyMenu.price | dinero }}</strong>
          </v-chip>
        </div>
        <div class="title pa-3 text-truncate">
          {{ dailyMenu.name | translate($i18n) }}
        </div>
      </v-card-title>
    </v-img>

    <v-card-text class="pb-0 flex-grow-1">
      <span>Descripción</span>
      <br />
      <!-- Check if the current description has any value using the translate filter -->
      <div
        v-if="!!$options.filters.translate(dailyMenu.description, $i18n)"
        class="text--primary text-truncate"
      >
        {{ dailyMenu.description | translate($i18n) }}
      </div>
      <div v-else class="text--primary text-truncate font-italic">
        {{ $t('dailyMenus.dailyMenu.noDescription') }}
      </div>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn
        fab
        dark
        small
        color="primary"
        @click.stop="$emit('daily-menu-products')"
      >
        <v-icon>mdi-ballot-outline</v-icon>
      </v-btn>
      <v-btn
        fab
        dark
        small
        color="red"
        @click.stop="$emit('daily-menu-delete')"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    dailyMenu: Object,
  },
};
</script>
