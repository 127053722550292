<template>
  <v-alert
    :value="error"
    border="left"
    colored-border
    type="error"
    elevation="2"
    class="mt-5 mb-0"
  >
    <div>
      <strong v-if="code">{{ code }}</strong>
      {{ message }}
    </div>
    <div v-if="errorsList.length > 0">
      <ul>
        <li v-for="e in errorsList" :key="e">{{ e }}</li>
      </ul>
    </div>
  </v-alert>
</template>

<script>
export default {
  props: {
    error: {},
    errors: {},
    code: {},
    message: {},
  },

  computed: {
    errorsList() {
      const list = [];

      for (let attr in this.errors) {
        if (Array.isArray(this.errors[attr])) {
          list.push(...this.errors[attr]);
        }
      }

      return list;
    },
  },
};
</script>

<style></style>
