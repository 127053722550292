<template>
  <v-main>
    <v-container fluid page v-if="!loading">
      <v-row align="center">
        <eximyr-breadcrumb :breadcrumbs="breadcrumbs"></eximyr-breadcrumb>

        <v-col cols="12">
          <h2 class="display-1 font-weight-light pl-5">Configuración menú</h2>
        </v-col>

        <v-col cols="12" v-if="error">
          <!-- Error message -->
          <v-alert
            border="left"
            colored-border
            type="error"
            elevation="2"
            class="mt-5 mb-0"
          >
            <strong v-if="code">{{ code }}</strong>
            {{ message }}
          </v-alert>
          <!-- Error message -->
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-stepper :value="step" @change="setStep" vertical>
            <!-- Configuration -->
            <v-stepper-step
              :complete="step > 1"
              step="1"
              editable
              :rules="[() => validConfig]"
            >
              Configuración
              <small class="pt-1"
                >Aquí se pueden configurar las diferentes opciones y precios del
                menú</small
              >
            </v-stepper-step>

            <v-stepper-content step="1">
              <eximyr-step-config
                :step="step"
                :config="config"
                v-if="!loading"
                @next-step="setStep(2)"
                @validation="validConfig = $event"
              ></eximyr-step-config>
            </v-stepper-content>
            <!-- Configuration -->

            <!-- Beverages -->
            <v-stepper-step :complete="step > 2" step="2" editable>
              Bebidas
              <small class="pt-1"
                >En este paso añadiremos las bebidas que se pueden
                elegir.</small
              >
            </v-stepper-step>
            <v-stepper-content step="2">
              <v-list two-line subheader>
                <eximyr-step-product
                  v-for="(product, index) in beverages"
                  :key="product.id"
                  :product="product"
                  :last="index + 1 == beverages.length"
                  @product-edit="edit(product, 'BEVERAGES')"
                  @product-delete="remove(product, 'BEVERAGES')"
                ></eximyr-step-product>
              </v-list>

              <eximyr-step-no-product
                v-if="beverages.length == 0"
                title="Sin bebidas."
                subtitle="No hay ningúna bebida dada de alta para este menú. Si desea crear una nueva utilice el botón 'añadir' de abajo."
              ></eximyr-step-no-product>

              <v-btn text @click="create('BEVERAGES')" class="mr-2"
                >Añadir</v-btn
              >
              <v-btn color="primary" @click="setStep(3)">Continuar</v-btn>
            </v-stepper-content>
            <!-- Beverages -->

            <!-- First dishes -->
            <v-stepper-step :complete="step > 3" step="3" editable>
              Primer plato
              <small class="pt-1">Continuamos con los primeros platos.</small>
            </v-stepper-step>
            <v-stepper-content step="3">
              <v-list two-line subheader>
                <eximyr-step-product
                  v-for="(product, index) in first_dishes"
                  :key="product.id"
                  :product="product"
                  :last="index + 1 == first_dishes.length"
                  @product-edit="edit(product, 'FIRST_DISHES')"
                  @product-delete="remove(product, 'FIRST_DISHES')"
                ></eximyr-step-product>
              </v-list>

              <eximyr-step-no-product
                v-if="first_dishes.length == 0"
                title="Sin primeros platos."
                subtitle="No hay ningún plato dado de alta para este menú. Si desea crear uno nuevo utilice el botón 'añadir' de abajo."
              ></eximyr-step-no-product>

              <v-btn text @click="create('FIRST_DISHES')" class="mr-2"
                >Añadir</v-btn
              >
              <v-btn color="primary" @click="setStep(4)">Continuar</v-btn>
            </v-stepper-content>
            <!-- First dishes -->

            <!-- Second dishes -->
            <v-stepper-step :complete="step > 4" step="4" editable>
              Segundo plato
              <small class="pt-1">Seguimos con los segundos.</small>
            </v-stepper-step>
            <v-stepper-content step="4">
              <v-list two-line subheader>
                <eximyr-step-product
                  v-for="(product, index) in second_dishes"
                  :key="product.id"
                  :product="product"
                  :last="index + 1 == second_dishes.length"
                  @product-edit="edit(product, 'SECOND_DISHES')"
                  @product-delete="remove(product, 'SECOND_DISHES')"
                ></eximyr-step-product>
              </v-list>

              <eximyr-step-no-product
                v-if="second_dishes.length == 0"
                title="Sin segundos platos."
                subtitle="No hay ningún plato dado de alta para este menú. Si desea crear uno nuevo utilice el botón 'añadir' de abajo."
              ></eximyr-step-no-product>

              <v-btn text @click="create('SECOND_DISHES')" class="mr-2"
                >Añadir</v-btn
              >
              <v-btn color="primary" @click="setStep(5)">Continuar</v-btn>
            </v-stepper-content>
            <!-- Second dishes -->

            <!-- Desserts -->
            <v-stepper-step :complete="step > 5" step="5" editable>
              Postres
              <small class="pt-1">Los dulces son imprescindibles.</small>
            </v-stepper-step>
            <v-stepper-content step="5">
              <v-list two-line subheader>
                <eximyr-step-product
                  v-for="(product, index) in desserts"
                  :key="product.id"
                  :product="product"
                  :last="index + 1 == desserts.length"
                  @product-edit="edit(product, 'DESSERTS')"
                  @product-delete="remove(product, 'DESSERTS')"
                ></eximyr-step-product>
              </v-list>

              <eximyr-step-no-product
                v-if="desserts.length == 0"
                title="Sin postres."
                subtitle="No hay ningún postre dado de alta para este menú. Si desea crear uno nuevo utilice el botón 'añadir' de abajo."
              ></eximyr-step-no-product>

              <v-btn text @click="create('DESSERTS')" class="mr-2"
                >Añadir</v-btn
              >
              <v-btn color="primary" @click="setStep(6)">Continuar</v-btn>
            </v-stepper-content>
            <!-- Desserts -->

            <!-- Coffees -->
            <v-stepper-step :complete="step > 6" step="6" editable>
              Cafés e infusiones
              <small class="pt-1"
                >Para terminar presentaremos los cafés y las infusiones.</small
              >
            </v-stepper-step>
            <v-stepper-content step="6">
              <v-list two-line subheader>
                <eximyr-step-product
                  v-for="(product, index) in coffees"
                  :key="product.id"
                  :product="product"
                  :last="index + 1 == coffees.length"
                  @product-edit="edit(product, 'COFFEES')"
                  @product-delete="remove(product, 'COFFEES')"
                ></eximyr-step-product>
              </v-list>

              <eximyr-step-no-product
                v-if="coffees.length == 0"
                title="Sin cafes o infusiones"
                subtitle="No hay ningún café dado de alta para este menú. Si desea crear uno nuevo utilice el botón 'añadir' de abajo."
              ></eximyr-step-no-product>

              <v-btn text @click="create('COFFEES')" class="mr-2">Añadir</v-btn>
              <v-btn color="primary" :to="{ name: 'daily-menus' }"
                >Finalizar</v-btn
              >
            </v-stepper-content>
            <!-- Coffees -->
          </v-stepper>
        </v-col>
      </v-row>
    </v-container>

    <!-- Remove buttom sheet -->
    <v-bottom-sheet v-model="removeDialog">
      <v-sheet class="text-center" height="200px" v-if="product">
        <div class="pt-6 px-3">
          ¿Estás seguro que quieres eliminar
          <span class="font-weight-medium">{{
            product.name | translate($i18n)
          }}</span
          >?
        </div>

        <v-btn class="mt-6 mx-2" @click="removeCanceled">Cancelar</v-btn>
        <v-btn class="mt-6 mx-2" color="error" @click="removeConfirmed"
          >Eliminar</v-btn
        >
      </v-sheet>
    </v-bottom-sheet>
    <!-- Remove buttom sheet -->

    <!-- Loading screen -->
    <eximyr-loading :loading="loading"></eximyr-loading>
    <!-- Loading screen -->
  </v-main>
</template>

<script>
import errors from '../mixins/errors';

import { mapGetters } from 'vuex';

import EximyrStepConfig from './components/dailymenu/EximyrStepConfig';
import EximyrStepProduct from './components/dailymenu/EximyrStepProduct';
import EximyrStepNoProduct from './components/dailymenu/EximyrStepNoProduct';

import EximyrBreadcrumb from '../components/EximyrBreadcrumb';
import EximyrLoading from '../components/EximyrLoading';

export default {
  mixins: [errors],

  components: {
    EximyrStepConfig,
    EximyrStepProduct,
    EximyrStepNoProduct,

    EximyrBreadcrumb,
    EximyrLoading,
  },

  data: () => ({
    validConfig: true,

    product: null,
    group: null,
    removeDialog: false,
    editDialog: false,
  }),
  computed: {
    ...mapGetters('languages', {
      languages: 'languages',
    }),

    ...mapGetters('dailyMenuProducts', {
      dailyMenu: 'dailyMenu',
      config: 'config',
      products: 'products',
      beverages: 'beverages',
      first_dishes: 'first_dishes',
      second_dishes: 'second_dishes',
      desserts: 'desserts',
      coffees: 'coffees',

      step: 'step',

      loading: 'loading',
    }),

    breadcrumbs() {
      return [
        {
          text: this.$t('navigation.dailyMenus'),
          disabled: false,
          exact: true,
          to: { name: 'daily-menus' },
        },
        {
          text: this.computedName,
          disabled: false,
          exact: false,
          to: { name: 'daily-menus.products' },
        },
      ];
    },

    computedName() {
      if (!this.dailyMenu.name) return '';
      return this.dailyMenu.name[this.$i18n.locale]
        ? this.dailyMenu.name[this.$i18n.locale]
        : this.dailyMenu.name[this.$i18n.fallbackLocale];
    },
  },

  created() {
    // Load the daily menu
    this.$store
      .dispatch('dailyMenuProducts/load', {
        restaurant: this.$route.params.restaurant,
        dailyMenu: this.$route.params.dailyMenu,
      })
      .then(() => {
        // console.log('ok');
      })
      .catch(error => {
        this.storeError(error);
      });
  },

  methods: {
    create(group) {
      // Set the group first
      this.$store.dispatch('dailyMenuProducts/setGroup', group);
      this.$router.push({
        name: 'daily-menus.products.create',
        params: {
          restaurant: this.$route.params.restaurant,
          dailyMenu: this.$route.params.dailyMenu,
        },
      });
    },

    edit(product) {
      // Go to the edit daily menu product endpoint
      this.$router.push({
        name: 'daily-menus.products.edit',
        params: {
          restaurant: this.$route.params.restaurant,
          dailyMenu: this.$route.params.dailyMenu,
          product: product.id,
        },
      });
    },

    editCanceled() {
      this.product = null;
      this.editDialog = false;
    },

    remove(product) {
      this.product = product;
      this.removeDialog = true;
    },

    removeConfirmed() {
      // Remove this product and reload
      this.$store
        .dispatch('dailyMenuProducts/remove', this.product)
        .then(() => {
          this.clearErrors();

          // Once deleted, reload all products in this category
          this.$store.dispatch('dailyMenuProducts/load', {
            restaurant: this.$route.params.restaurant,
            dailyMenu: this.$route.params.dailyMenu,
          });
        })
        .catch(error => {
          this.storeError(error);
        });

      this.product = null;
      this.removeDialog = false;
    },

    removeCanceled() {
      this.product = null;
      this.removeDialog = false;
    },

    setStep(step) {
      this.$store.dispatch('dailyMenuProducts/setStep', step);
    },
  },
};
</script>

<style></style>
