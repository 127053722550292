<template>
  <v-form ref="form">
    <v-row>
      <v-col cols="12">
        <v-checkbox
          v-model="coffee_dessert"
          label="Incluye tanto postre como café."
          color="primary"
          hide-details
        ></v-checkbox>
      </v-col>

      <v-col cols="12">
        <v-checkbox
          v-model="only_first_dishes"
          label="Se puede pedir solo un primer plato."
          color="primary"
        ></v-checkbox>

        <v-text-field
          label="Precio menú solo primer plato (en céntimos) *"
          :counter="10"
          prepend-icon="mdi-currency-eur"
          :disabled="!only_first_dishes"
          v-model="price_only_first_dishes"
          :rules="rules.only_first_dishes"
        ></v-text-field>
      </v-col>

      <v-col cols="12">
        <v-checkbox
          v-model="two_first_dishes"
          label="Se pueden pedir dos primeros."
          color="primary"
          hide-details
        ></v-checkbox>
        <v-text-field
          label="Precio menú dos primeros (en céntimos) *"
          :counter="10"
          prepend-icon="mdi-currency-eur"
          :disabled="!two_first_dishes"
          v-model="price_two_first_dishes"
          :rules="rules.two_first_dishes"
        ></v-text-field>
      </v-col>

      <v-col cols="12">
        <v-checkbox
          v-model="only_second_dishes"
          label="Se puede pedir solo un segundo plato."
          color="primary"
          hide-details
        ></v-checkbox>
        <v-text-field
          label="Precio menú solo segundo plato (en céntimos) *"
          :counter="10"
          prepend-icon="mdi-currency-eur"
          :disabled="!only_second_dishes"
          v-model="price_only_second_dishes"
          :rules="rules.only_second_dishes"
        ></v-text-field>
      </v-col>

      <v-col cols="12">
        <v-checkbox
          v-model="two_second_dishes"
          label="Se pueden pedir dos segundos."
          color="primary"
          hide-details
        ></v-checkbox>
        <v-text-field
          label="Precio menú dos segundos (en céntimos) *"
          :counter="10"
          prepend-icon="mdi-currency-eur"
          :disabled="!two_second_dishes"
          v-model="price_two_second_dishes"
          :rules="rules.two_second_dishes"
        ></v-text-field>
      </v-col>

      <v-col cols="12">
        <v-btn color="primary" @click="nextStep">Continue</v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { requiredIf } from '@/validators/requiredIf';
import { length } from '@/validators/length';
import { numeric } from '@/validators/numeric';

export default {
  props: {
    config: Object,
    step: {
      required: true,
    },
  },

  data: () => ({
    coffee_dessert: true,

    only_first_dishes: false,
    two_first_dishes: false,
    only_second_dishes: false,
    two_second_dishes: false,

    price_only_first_dishes: '',
    price_two_first_dishes: '',
    price_only_second_dishes: '',
    price_two_second_dishes: '',
  }),

  computed: {
    rules() {
      return {
        only_first_dishes: [
          requiredIf(this, this.only_first_dishes),
          numeric(this),
          length(this, 10),
        ],
        two_first_dishes: [
          requiredIf(this, this.two_first_dishes),
          numeric(this),
          length(this, 10),
        ],
        only_second_dishes: [
          requiredIf(this, this.only_second_dishes),
          numeric(this),
          length(this, 10),
        ],
        two_second_dishes: [
          requiredIf(this, this.two_second_dishes),
          numeric(this),
          length(this, 10),
        ],
      };
    },
  },

  created() {
    this.coffee_dessert = this.config.coffee_dessert;

    this.only_first_dishes = this.config.only_first_dishes;
    this.two_first_dishes = this.config.two_first_dishes;
    this.only_second_dishes = this.config.only_second_dishes;
    this.two_second_dishes = this.config.two_second_dishes;

    this.price_only_first_dishes = this.config.price_only_first_dishes;
    this.price_two_first_dishes = this.config.price_two_first_dishes;
    this.price_only_second_dishes = this.config.price_only_second_dishes;
    this.price_two_second_dishes = this.config.price_two_second_dishes;
  },

  watch: {
    // You CANNOT use lambda method here, 'this' will be null
    step: function (nextStep, oldStep) {
      if (oldStep != 1 || nextStep == 1) {
        // The step is just changing between the other 6 steps
        return;
      }

      // Fire the validation event
      this.$emit('validation', this.$refs.form.validate());

      // If there are errors then discart all changes...
      if (!this.$refs.form.validate()) {
        return;
      }

      // Now we need to save this data
      this.save();
    },
  },

  methods: {
    nextStep() {
      this.$emit('next-step');
    },

    save() {
      let config = {
        coffee_dessert: this.coffee_dessert,

        only_first_dishes: this.only_first_dishes,
        two_first_dishes: this.two_first_dishes,
        only_second_dishes: this.only_second_dishes,
        two_second_dishes: this.two_second_dishes,

        price_only_first_dishes: this.price_only_first_dishes,
        price_two_first_dishes: this.price_two_first_dishes,
        price_only_second_dishes: this.price_only_second_dishes,
        price_two_second_dishes: this.price_two_second_dishes,
      };

      let payload = {
        restaurant: this.$route.params.restaurant,
        dailyMenu: this.$route.params.dailyMenu,
        dailyMenuConfig: config,
      };

      this.$store.dispatch('dailyMenuProducts/updateConfig', payload);
    },
  },
};
</script>

<style></style>
