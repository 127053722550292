<template>
  <v-main>
    <v-container fluid v-if="!loading">
      <v-row align="center" justify="center">
        <eximyr-breadcrumb :breadcrumbs="breadcrumbs"></eximyr-breadcrumb>

        <v-col cols="12">
          <span v-if="create" class="display-1 font-weight-light pl-5">
            Crear nuevo producto
          </span>
          <span v-else class="display-1 font-weight-light pl-5">
            Editar producto
          </span>
        </v-col>

        <v-col cols="12" v-if="error">
          <!-- Error message -->
          <v-alert
            border="left"
            colored-border
            type="error"
            elevation="2"
            class="mt-5 mb-0"
          >
            <strong v-if="code">{{ code }}</strong>
            {{ message }}
          </v-alert>
          <!-- Error message -->
        </v-col>
      </v-row>

      <v-row class="flex-row-reverse">
        <v-col cols="12">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-form ref="form">
                    <v-row>
                      <v-col cols="12" class="py-0">
                        <v-card
                          outlined
                          class="my-2"
                          v-for="l in languages"
                          :key="l.code"
                        >
                          <v-card-text>
                            <v-card-title class="subtitle-1">{{
                              l.language
                            }}</v-card-title>

                            <v-text-field
                              label="Nombre del producto *"
                              :counter="150"
                              prepend-icon="mdi-label-outline"
                              required
                              v-model="name[l.code]"
                              :rules="rules.name"
                            >
                              <template v-slot:append>
                                <!-- Language image -->
                                <img
                                  width="24"
                                  height="24"
                                  :src="l.image_path"
                                  :alt="l.language"
                                />
                              </template>
                            </v-text-field>

                            <v-textarea
                              :counter="255"
                              prepend-icon="mdi-file-document-edit-outline"
                              label="Descripción del producto"
                              v-model="description[l.code]"
                              :rules="rules.description"
                            >
                              <template v-slot:append>
                                <img
                                  width="24"
                                  height="24"
                                  :src="l.image_path"
                                  :alt="l.language"
                                />
                              </template>
                            </v-textarea>
                          </v-card-text>
                        </v-card>
                      </v-col>

                      <v-col cols="12" class="py-0">
                        <v-select
                          v-model="allergens"
                          :items="allergensList"
                          label="¿Qué alérgenos contiene este producto?"
                          multiple
                          chips
                          item-value="id"
                          :item-text="itemText"
                          prepend-icon="mdi-peanut-outline"
                          deletable-chips
                        ></v-select>
                      </v-col>

                      <v-col cols="12" class="py-0">
                        <v-text-field
                          label="Precio del producto (en centimos)"
                          :counter="10"
                          required
                          prepend-icon="mdi-currency-eur"
                          v-model="price"
                          :rules="rules.price"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" class="py-0">
                        <v-text-field
                          label="Prioridad de orden (mayor número antes aparece en la lista) *"
                          :counter="10"
                          required
                          prepend-icon="mdi-sort"
                          v-model="display_priority"
                          :rules="rules.display"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" class="py-0">
                        <v-switch
                          :prepend-icon="active ? 'mdi-eye' : 'mdi-eye-off'"
                          v-model="active"
                          label="Activar el producto"
                        ></v-switch>
                        <small class="text--primary"
                          >(*) Indica los campos obligatorios.</small
                        >
                      </v-col>
                    </v-row>
                  </v-form>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="pa-3">
              <v-btn
                v-if="isAdmin || isEmployee"
                :disabled="
                  translatedName == name[$i18n.locale] &&
                  translatedDescription == description[$i18n.locale]
                "
                color="primary"
                @click="googleTranslate"
              >
                Traducir Google
              </v-btn>

              <v-spacer></v-spacer>
              <v-btn class="mr-2" @click="cancel">Cancelar</v-btn>
              <v-btn color="primary" @click="save">Guardar</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- Loading screen -->
    <eximyr-loading
      :loading="loading || loadingLanguages || loadingTranslation"
    ></eximyr-loading>
    <!-- Loading screen -->
  </v-main>
</template>

<script>
import { mapGetters } from 'vuex';

import { required } from '@/validators/required';
import { length } from '@/validators/length';
import { numeric } from '@/validators/numeric';

// Language and errors loader mixin
import languages from '../mixins/languages';
import errors from '../mixins/errors';

import EximyrBreadcrumb from '../components/EximyrBreadcrumb';
import EximyrLoading from '../components/EximyrLoading';

export default {
  components: { EximyrBreadcrumb, EximyrLoading },

  mixins: [languages, errors],

  data: () => ({
    create: false,

    id: 0,
    restaurant_uuid: 0,
    daily_menu_id: 0,

    // All languages are needed so we can have reactivity
    name: { de: null, en: null, es: null, it: null, fr: null, pt: null },
    description: { de: null, en: null, es: null, it: null, fr: null, pt: null },

    allergens: [],
    group: '',
    price: '',
    active: true,
    display_priority: 100,

    // We need all allergens here
    allergensList: [],

    loadingTranslation: false,
    translatedName: '', // Prevent multiple clicks
    translatedDescription: '', // Prevent multiple clicks
  }),

  computed: {
    ...mapGetters('security', {
      isAdmin: 'isAdmin',
      isEmployee: 'isEmployee',
    }),

    ...mapGetters('dailyMenus', {
      dailyMenu: 'dailyMenu',
    }),

    ...mapGetters('dailyMenuProducts', {
      product: 'product',

      defaultGroup: 'group', // Needed for creating new product
      step: 'step', // Needed for the back to daily menu products link

      loading: 'loading',
    }),

    itemText() {
      // Needed in the select for category substitution (remove category feature)
      return 'name[' + this.$i18n.locale + ']';
    },

    breadcrumbs() {
      const breadcrumbs = [
        {
          text: this.$t('navigation.dailyMenus'),
          disabled: false,
          exact: true,
          to: { name: 'daily-menus' },
        },
        {
          text: this.dailyMenuName,
          disabled: false,
          exact: true,
          to: { name: 'daily-menus.products' },
        },
      ];

      // Dynamically create the edit or create breadcrumb
      if (this.create) {
        breadcrumbs.push({
          text: this.$t('products.breadcrumb.create'),
          disabled: true,
          exact: false,
        });
      } else {
        breadcrumbs.push({
          text: this.$t('products.breadcrumb.edit', {
            name: this.computedName,
          }),
          disabled: true,
          exact: false,
        });
      }

      return breadcrumbs;
    },

    dailyMenuName() {
      if (!this.dailyMenu.name) return '';
      return this.dailyMenu.name[this.$i18n.locale]
        ? this.dailyMenu.name[this.$i18n.locale]
        : this.dailyMenu.name[this.$i18n.fallbackLocale];
    },

    computedName() {
      return this.name[this.$i18n.locale]
        ? this.name[this.$i18n.locale]
        : this.name[this.$i18n.fallbackLocale];
    },

    // Validation errors
    rules() {
      return {
        name: [required(this), length(this, 150)],
        description: [length(this, 255)],
        price: [numeric(this), length(this, 10)],
        display: [required(this), numeric(this), length(this, 10)],
      };
    },
  },

  created() {
    // First we load the daily menu
    this.$store.dispatch('dailyMenus/loadDailyMenu', {
      restaurant: this.$route.params.restaurant,
      dailyMenu: this.$route.params.dailyMenu,
    });

    // Now we load all allergens
    this.$store
      .dispatch('allergens/all', this.$route.params.restaurant)
      .then(response => {
        this.allergensList = response.data;
      })
      .catch(error => {
        this.storeError(error);
      });

    // Check if we are in edit mode or in create mode
    if (!this.$route.params.product) {
      this.group = this.defaultGroup;
      this.daily_menu_id = this.$route.params.dailyMenu;
      this.create = true;
      return;
    }

    this.$store
      .dispatch('dailyMenuProducts/loadProduct', {
        restaurant: this.$route.params.restaurant,
        dailyMenu: this.$route.params.dailyMenu,
        product: this.$route.params.product,
      })
      .then(response => {
        this.id = response.data.id;
        this.restaurant_uuid = response.data.restaurant_uuid;
        this.daily_menu_id = response.data.daily_menu_id;

        // We need to copy all data from one to another - using this
        // for we preserve older data
        let locale;
        for (locale in response.data.name) {
          this.name[locale] = response.data.name[locale];
        }
        for (locale in response.data.description) {
          this.description[locale] = response.data.description[locale];
        }

        this.price = response.data.price;
        this.group = response.data.group;
        this.active = response.data.active;
        this.display_priority = response.data.display_priority;

        // Load only id of all allergens
        this.allergens = response.data.allergens.map(allergen => allergen.id);
      })
      .catch(error => {
        this.storeError(error);
      });
  },

  methods: {
    cancel() {
      this.$router.push({ name: 'daily-menus.products' });
    },

    save() {
      if (!this.$refs.form.validate()) {
        return;
      }

      let product = {
        restaurant_uuid: this.$route.params.restaurant,
        daily_menu_id: this.daily_menu_id,
        name: this.name,
        description: this.description,
        price: this.price,
        group: this.group,
        allergens: this.allergens,
        active: this.active,
        display_priority: this.display_priority,
      };

      let action = '';
      if (this.create) {
        // Create new product
        action = 'dailyMenuProducts/create';
      } else {
        // Update the product - set the product's id
        product.id = this.product.id;
        action = 'dailyMenuProducts/update';
      }

      // Update / create the product
      this.$store
        .dispatch(action, product)
        .then(() => {
          // Once created, go back to the products page
          this.$router.push({ name: 'daily-menus.products' });
        })
        .catch(error => {
          this.storeError(error);
        });
    },

    googleTranslate() {
      this.loadingTranslation = true;
      this.clearErrors();
      // Get spanish translations
      // console.log(this.name[this.$i18n.locale]);
      // console.log(this.description[this.$i18n.locale]);

      const payload = {
        input: [
          this.name[this.$i18n.locale],
          this.description[this.$i18n.locale],
        ],
        source: this.$i18n.locale,
        // Target all locales but the default one 'es'
        target: Object.keys(this.name).filter(
          locale => locale !== this.$i18n.locale
        ),
      };

      this.$store
        .dispatch('translation/translate', payload)
        .then(response => {
          // Get all locales from the name
          Object.keys(this.name)
            // Remove the source locale (es)
            .filter(locale => locale !== this.$i18n.locale)
            // Save all translations for the name and description
            .forEach(code => {
              this.name[code] = response.data[code]['0'].text;
              if (response.data[code]['1']) {
                this.description[code] = response.data[code]['1'].text;
              }
            });

          // Prevent multiple clicks for the same translations
          this.translatedName = this.name[this.$i18n.locale];
          this.translatedDescription = this.description[this.$i18n.locale];
        })
        .catch(error => {
          this.storeError(error);
          this.error(error.response);
        })
        .finally(() => {
          this.loadingTranslation = false;
        });
    },
  },
};
</script>

<style></style>
