<template>
  <v-main>
    <v-container fluid page v-if="!loading">
      <v-row align="center" justify="center">
        <eximyr-breadcrumb :breadcrumbs="breadcrumbs"></eximyr-breadcrumb>

        <v-col cols="12">
          <h2 v-if="create" class="display-1 font-weight-light pl-5">
            Crear nuevo menú del día
          </h2>
          <h2 v-else class="display-1 font-weight-light pl-5">
            Editar menú del día
          </h2>
        </v-col>

        <v-col cols="12">
          <eximyr-errors
            :error="error"
            :errors="errors"
            :code="code"
            :message="message"
          ></eximyr-errors>
        </v-col>
      </v-row>

      <v-row class="flex-row-reverse">
        <v-col cols="12">
          <v-card>
            <v-card-text>
              <v-row class="flex-row-reverse">
                <!-- Preview card -->
                <v-col cols="12" md="4">
                  <div class="text-center headline font-weight-thin mt-5 mb-3">
                    Vista previa
                  </div>

                  <v-card class="mx-auto header category" max-width="450">
                    <v-img class="white--text" height="200px" :src="image">
                      <v-card-title
                        class="align-content-space-between fill-height pa-0"
                      >
                        <div class="pa-3 text-truncate text-right options">
                          <v-chip class="ma-2 elevation-3" color="white">
                            <v-avatar left>
                              <v-icon>{{
                                active ? 'mdi-eye' : 'mdi-eye-off'
                              }}</v-icon>
                            </v-avatar>
                            {{ active ? 'Activa' : 'No visible' }}
                          </v-chip>
                        </div>
                        <div class="title pa-3 text-truncate">
                          {{ name | translate($i18n) }}
                        </div>
                      </v-card-title>
                    </v-img>
                  </v-card>

                  <div v-if="isAdmin || isEmployee">
                    <v-btn
                      :disabled="
                        translatedName == name[$i18n.locale] &&
                        translatedDescription == description[$i18n.locale] &&
                        translatedNotes == notes[$i18n.locale]
                      "
                      block
                      max-width="400"
                      color="primary"
                      @click="googleTranslate"
                    >
                      Traducir Google
                    </v-btn>
                  </div>
                </v-col>
                <!-- Preview card -->

                <v-col cols="12" md="8">
                  <v-form ref="form">
                    <v-row>
                      <v-col cols="12" class="py-0">
                        <v-file-input
                          accept="image/*"
                          label="Seleccione una imagen"
                          @change="onFilePicked"
                          validate-on-blur
                          :rules="rules.image"
                        ></v-file-input>
                      </v-col>

                      <v-col cols="12" class="py-0">
                        <v-card
                          outlined
                          class="my-2"
                          v-for="l in languages"
                          :key="l.code"
                        >
                          <v-card-text>
                            <v-card-title class="subtitle-1">
                              {{ l.language }}
                            </v-card-title>

                            <v-text-field
                              label="Título de la menú *"
                              :counter="150"
                              prepend-icon="mdi-label-outline"
                              required
                              v-model="name[l.code]"
                              :rules="rules.name"
                            >
                              <template v-slot:append>
                                <img
                                  width="24"
                                  height="24"
                                  :src="l.image_path"
                                  :alt="l.language"
                                />
                              </template>
                            </v-text-field>

                            <v-textarea
                              :counter="255"
                              prepend-icon="mdi-file-document-edit-outline"
                              label="Descripción del menú"
                              v-model="description[l.code]"
                              :rules="rules.description"
                            >
                              <template v-slot:append>
                                <img
                                  width="24"
                                  height="24"
                                  :src="l.image_path"
                                  :alt="l.language"
                                />
                              </template>
                            </v-textarea>

                            <v-textarea
                              :counter="255"
                              prepend-icon="mdi-note-plus-outline"
                              label="Notas del menú"
                              v-model="notes[l.code]"
                              :rules="rules.notes"
                            >
                              <template v-slot:append>
                                <img
                                  width="24"
                                  height="24"
                                  :src="l.image_path"
                                  :alt="l.language"
                                />
                              </template>
                            </v-textarea>
                          </v-card-text>
                        </v-card>
                      </v-col>

                      <v-col cols="12" class="py-0">
                        <v-text-field
                          label="Precio del menú (en centimos) *"
                          :counter="10"
                          required
                          prepend-icon="mdi-currency-eur"
                          v-model="price"
                          :rules="rules.price"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" class="py-0">
                        <v-switch
                          :prepend-icon="active ? 'mdi-eye' : 'mdi-eye-off'"
                          v-model="active"
                          label="Activar el menú del día"
                          :disabled="this.create"
                        ></v-switch>
                        <small class="text--primary">
                          (*) Indica los campos obligatorios.
                        </small>
                      </v-col>

                      <v-col cols="12" class="text-right">
                        <v-btn class="mr-2" @click="cancel">Cancelar</v-btn>
                        <v-btn color="primary" @click="save">Guardar</v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- Loading screen -->
    <eximyr-loading
      :loading="loading || loadingLanguages || loadingTranslation"
    ></eximyr-loading>
  </v-main>
</template>

<script>
import errors from '../mixins/errors';
import { IMAGE_DEFAULT_DAILY_MENU } from '@/constants';
import { mapGetters } from 'vuex';

import goTo from 'vuetify/lib/services/goto';

import { required } from '@/validators/required';
import { length } from '@/validators/length';
import { numeric } from '@/validators/numeric';
import { fileSize } from '@/validators/fileSize';

// Language loader mixin
import languages from '../mixins/languages';

import EximyrBreadcrumb from '../components/EximyrBreadcrumb';
import EximyrErrors from '../components/EximyrErrors';
import EximyrLoading from '../components/EximyrLoading';

export default {
  components: { EximyrBreadcrumb, EximyrErrors, EximyrLoading },

  mixins: [languages, errors],

  data: () => ({
    create: false,

    id: 0,
    restaurant_uuid: 0,

    // All languages are needed so we can have reactivity
    name: { de: null, en: null, es: null, it: null, fr: null, pt: null },
    description: { de: null, en: null, es: null, it: null, fr: null, pt: null },
    notes: { de: null, en: null, es: null, it: null, fr: null, pt: null },

    price: '',
    active: false,

    image: IMAGE_DEFAULT_DAILY_MENU,
    imageFile: '',

    loadingTranslation: false,
    translatedName: '', // Prevent multiple clicks
    translatedDescription: '', // Prevent multiple clicks
    translatedNotes: '', // Prevent multiple clicks
  }),

  computed: {
    ...mapGetters('security', {
      isAdmin: 'isAdmin',
      isEmployee: 'isEmployee',
    }),

    ...mapGetters('dailyMenus', {
      dailyMenu: 'dailyMenu',
      loading: 'loading',
    }),

    breadcrumbs() {
      const breadcrumbs = [
        {
          text: this.$t('navigation.dailyMenus'),
          disabled: false,
          exact: true,
          to: { name: 'daily-menus' },
        },
      ];

      if (this.create) {
        breadcrumbs.push({
          text: this.$t('dailyMenus.breadcrumb.create'),
          disabled: true,
          exact: false,
        });
      } else {
        breadcrumbs.push({
          text: this.$t('dailyMenus.breadcrumb.edit', {
            name: this.computedName,
          }),
          disabled: true,
          exact: false,
        });
      }

      return breadcrumbs;
    },

    computedName() {
      return this.name[this.$i18n.locale]
        ? this.name[this.$i18n.locale]
        : this.name[this.$i18n.fallbackLocale];
    },

    // Validation errors
    rules() {
      return {
        image: [fileSize(this, 512)],
        name: [required(this), length(this, 150)],
        description: [length(this, 255)],
        notes: [length(this, 255)],
        price: [required(this), numeric(this), length(this, 10)],
      };
    },
  },

  created() {
    // Check if we are in edit mode or in create mode
    if (!this.$route.params.dailyMenu) {
      this.create = true;
      return;
    }

    this.$store
      .dispatch('dailyMenus/loadDailyMenu', {
        restaurant: this.$route.params.restaurant,
        dailyMenu: this.$route.params.dailyMenu,
      })
      .then(response => {
        this.id = response.data.id;
        this.restaurant_uuid = response.data.restaurant_uuid;
        this.image = response.data.image_path;

        // We need to copy all data from one to another - using this
        // for we preserve older data
        let locale;
        for (locale in response.data.name) {
          this.name[locale] = response.data.name[locale];
        }
        for (locale in response.data.description) {
          this.description[locale] = response.data.description[locale];
        }
        for (locale in response.data.notes) {
          this.notes[locale] = response.data.notes[locale];
        }

        this.price = response.data.price;
        this.active = response.data.active;
      })
      .catch(error => {
        this.storeError(error);
      });
  },

  methods: {
    cancel() {
      this.$router.push({
        name: 'daily-menus',
        params: {
          restaurant: this.$route.params.restaurant,
        },
      });
    },

    save() {
      if (!this.$refs.form.validate()) {
        goTo(0);
        return;
      }

      let dailyMenu = {
        restaurant_uuid: this.$route.params.restaurant,
        image: this.imageFile,
        name: this.name,
        description: this.description,
        notes: this.notes,
        price: this.price,
        active: this.active,
      };

      let action = '';
      if (this.create) {
        // Create new daily menu
        action = 'dailyMenus/create';
      } else {
        // Update the daily menu - set the id
        dailyMenu.id = this.dailyMenu.id;
        action = 'dailyMenus/update';
      }

      // Update / create the daily menu
      this.$store
        .dispatch(action, dailyMenu)
        .then(() => {
          this.$router.push({
            name: 'daily-menus',
            params: {
              restaurant: this.$route.params.restaurant,
            },
          });
        })
        .catch(error => {
          this.storeError(error);
        });
    },

    onFilePicked(file) {
      if (file) {
        const fr = new FileReader();
        fr.readAsDataURL(file);
        fr.addEventListener('load', () => {
          this.image = fr.result;
          this.imageFile = file; // this is an image file that can be sent to server...
        });
      } else {
        this.imageFile = '';

        // We must show the latest image, if its a create action
        // now then use the default image
        this.image = this.create ? null : this.dailyMenu.image_path;
      }
    },

    googleTranslate() {
      this.loadingTranslation = true;
      this.clearErrors();
      // Get spanish translations
      // console.log(this.name[this.$i18n.locale]);
      // console.log(this.description[this.$i18n.locale]);

      const payload = {
        input: [
          this.name[this.$i18n.locale],
          this.description[this.$i18n.locale],
          this.notes[this.$i18n.locale],
        ],
        source: this.$i18n.locale,
        // Target all locales but the default one 'es'
        target: Object.keys(this.name).filter(
          locale => locale !== this.$i18n.locale
        ),
      };

      this.$store
        .dispatch('translation/translate', payload)
        .then(response => {
          // Get all locales from the name
          Object.keys(this.name)
            // Remove the source locale (es)
            .filter(locale => locale !== this.$i18n.locale)
            // Save all translations for the name and description
            .forEach(code => {
              this.name[code] = response.data[code]['0'].text;
              if (this.description[this.$i18n.locale]) {
                this.description[code] = response.data[code]['1'].text;
              }

              // If the descrption is null then the notes are returned in the 1 position
              if (
                !this.description[this.$i18n.locale] &&
                this.notes[this.$i18n.locale]
              ) {
                this.notes[code] = response.data[code]['1'].text;
              } else if (this.notes[this.$i18n.locale]) {
                this.notes[code] = response.data[code]['2'].text;
              }
            });

          // Prevent multiple clicks for the same translations
          this.translatedName = this.name[this.$i18n.locale];
          this.translatedDescription = this.description[this.$i18n.locale];
          this.translatedNotes = this.notes[this.$i18n.locale];
        })
        .catch(error => {
          this.storeError(error);
          this.error(error.response);
        })
        .finally(() => {
          this.loadingTranslation = false;
        });
    },
  },
};
</script>
