<template>
  <v-list two-line subheader>
    <v-list-item @click.stop>
      <v-list-item-avatar
        v-if="$vuetify.breakpoint.smAndUp"
        class="elevation-3"
      >
        <v-icon class="blue white--text">mdi-message-alert</v-icon>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title>{{ title }}</v-list-item-title>
        <v-list-item-subtitle>{{ subtitle }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  props: {
    title: String,
    subtitle: String,
  },
};
</script>

<style></style>
